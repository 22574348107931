<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button color="secondary" @click="closeForm">
            <ion-icon slot="icon-only" ios="close" md="close"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>{{ putTitle }}</ion-title>
      </ion-toolbar>
    </ion-header>
      
    <ion-content class="ion-padding">
      <ion-card>
        <ion-card-content>
          <ion-item>
            <ion-label>
              {{ bookDetail.name }} </ion-label>
          </ion-item>
          <ion-item>
            <ion-label>
              <p class="ion-text-wrap">Created by: <span v-if="bookDetail.name"> {{ bookDetail.createdBy.name }} - </span> {{ bookDetail.createdBy.email }}</p>  
            </ion-label>
          </ion-item>
          <ion-item v-if="bookDetail.hasAccessData.length > 1">
            <ion-label>
              <h4> Shared with:</h4>
              <ion-list v-for="shareItem in bookDetail.hasAccessData" :key="shareItem.index">
                <ion-item v-if="shareItem.email !== bookDetail.createdBy.email" >
                  <ion-icon class="stopShare" slot="end" :icon="trashOutline" @click="submitForm('stopShare', shareItem)" ></ion-icon>
                  <ion-label class="ion-text-wrap">
                    <span v-if="shareItem.name"> {{ shareItem.name }} - </span> {{ shareItem.email }} 
                  </ion-label>
                </ion-item>
              </ion-list>
            </ion-label>
          </ion-item>
          <ion-button @click="submitForm('renameBook')" expand="block">
            <ion-icon slot="start" :icon="createOutline"></ion-icon> Rename
          </ion-button>
          <ion-button @click="submitForm('editCategory')" expand="block">
            <ion-icon slot="start" :icon="albumsOutline"></ion-icon> Edit Category
          </ion-button>
          <ion-button @click="submitForm('addShare')" expand="block">
            <ion-icon slot="start" :icon="shareSocialOutline"></ion-icon> Share
          </ion-button>
           <ion-button  @click="closeForm" expand="block">
            <ion-icon slot="start" :icon="closeOutline"></ion-icon> Close
          </ion-button>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<style scoped>
  .stopShare{
    color: red;
  }
</style>

<script lang="ts">
import { 
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonIcon,
  IonHeader,
  IonItem,
  IonList,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/vue'
import { close, createOutline, closeOutline, shareSocialOutline,albumsOutline, trashOutline } from 'ionicons/icons'
import { defineComponent, reactive, computed } from 'vue'

export default defineComponent({
  name: 'IncomeForm',
  components: { 
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonContent,
    IonIcon,
    IonHeader,
    IonItem,
    IonList,
    IonLabel,
    IonPage,
    IonTitle,
    IonToolbar
  },
  props: { 
    bookProp: {
      type: Object,
      default: () => ({
        id: null,
        createdBy: {
          email: null,
          name: null,
          uid: null,
        },
        hasAccess: null,
        hasAccessData: [],
        name: null,
        settings: {
          categories: {
            income: [],
            expense: []
          }
        },
        updatedAt: null,
        createdAt: null
      })
    }
  },
  setup (props: any, ctx) {
    const bookDetail = reactive<any>({
      id: null,
      createdBy: {
        email: null,
        name: null,
        uid: null,
      },
      hasAccess: null,
      hasAccessData: [],
      name: null,
      settings: {
        categories: {
          income: [],
          expense: []
        }
      },
      updatedAt: null,
      createdAt: null
    })

    const putTitle = computed(() => {
      const result = 'Book Detail'
      Object.assign (bookDetail, props.bookProp)
      // console.log(bookDetail)
      return result
    })

    const submitForm = async (action: string, stopShare?: any) => {
      // console.log('submit Income Form')
      // console.log(formItem)

      const response = {
        'action': action,
        'bookDetail': bookDetail,
        'stopShare': stopShare
      }
      // console.log(response)
      ctx.emit('submit', response)
    }
    
    const closeForm = () => {
      // console.log('close Income Form')
      const response = {
        'action': 'close',
        'bookDetail': null
      }
      // console.log(response)
      ctx.emit('close', response)
    }

    return {
      //method
      submitForm,
      closeForm,
    
      //data
      bookDetail,

      //computed
      putTitle,

      //icon
      close,
      createOutline,
      closeOutline,
      shareSocialOutline,
      albumsOutline,
      trashOutline
    }
  }
})
</script>