<template>
  <ion-page>
    <AppHeader></AppHeader>

    <ion-content :fullscreen="true">
    
      <ion-card>
        <ion-card-header>
          <ion-card-subtitle>Welcome</ion-card-subtitle>
          <ion-card-title>MyFiApp</ion-card-title>
        </ion-card-header>

        <ion-card-content>
          Welcome MyFiApp<br>
          Please Created At Least 1 Book to continue
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-content>
          <ion-card-title>
            Available Books
          </ion-card-title>
        </ion-card-content>
        <ion-card-content>
          <div v-if="pageData.availableBooks > 0">
            <ion-list v-for="item in pageData.bookList" :key="item.id" lines="inset">
              <ion-item>
                <ion-icon v-if="item.id == pageData.selectedBook.id" slot="end" :icon="checkmarkCircleOutline" class="selectedBookCheck" @click="makeSelectedBook(item)"></ion-icon>
                <ion-icon v-else slot="end" :icon="checkmarkCircleOutline" class="unSelectedBookCheck" @click="makeSelectedBook(item)"></ion-icon>
                <ion-icon slot="end" :icon="informationCircleOutline" @click="showBookDetail(item)"></ion-icon>
                <ion-label>
                  {{ item.name }}
                  <div v-if="item.hasAccessData.length > 1">
                    <ion-text color="primary">
                      <h5>-- Shared --</h5>
                    </ion-text>
                  </div>
                </ion-label>
              </ion-item>
            </ion-list>
          </div>
          <div v-else>
            <p>No Books Available.</p>
            <p>Please Create One</p>
          </div>
          <ion-button @click="createBookAlert">
            <ion-icon slot="icon-only" :icon="add"></ion-icon>
            Create Books
          </ion-button>
        </ion-card-content>
      </ion-card>
      
      <!-- <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button @click="() => router.push('/new')">
          <ion-icon :icon="add"></ion-icon>
        </ion-fab-button>
      </ion-fab> -->

      <ion-modal
        :is-open="isCategoryOpenRef"
        css-class="my-custom-class"
        @onDidDismiss="setCategoryOpen(false)"
      >
        <CategoryForm :formData="formData" @close="closeCategoryForm" @submit="submitCategoryForm" />
      </ion-modal>

      <ion-modal
        :is-open="isBookDetailRef"
        css-class="my-custom-class"
        @onDidDismiss="setBookDetailOpen(false)"
      >
        <BookDetail :bookProp="bookDetailData" @close="setBookDetailOpen(false)" @submit="submitBookDetail"/>
      </ion-modal>

    </ion-content>
    
    <AppFooter :key="pageData.footerKey"/>

  </ion-page>
</template>

<style scoped>
.my-custom-class .modal-wrapper {
  background: #222;
  height: 50px;
}

.selectedBookCheck{
  color: blue;
}

.unSelectedBookCheck{
  color: magenta;
  cursor: pointer;
}
</style>

<script lang="ts">
import { 
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  // IonFab,
  // IonFabButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonText,
  alertController
} from '@ionic/vue'
import { defineComponent, onMounted, onUpdated, reactive, ref, onBeforeUpdate } from 'vue'
import { add, checkmarkCircleOutline, informationCircleOutline } from 'ionicons/icons'
import { useRouter } from 'vue-router'
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import useFirebaseFirestore from "../hooks/firebase-firestore"
import useGlobalHelpers from "../helpers/global-helpers"
import firebaseAuth from '@/hooks/firebase-auth'
import CategoryForm from '@/components/CategoryForm.vue'
import BookDetail from '@/components/BookDetail.vue'

export default defineComponent({
  name: 'Home',
  components: {
    IonButton,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonContent,
    // IonFab,
    // IonFabButton,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonPage,
    IonText,
    AppHeader,
    AppFooter,
    CategoryForm,
    BookDetail
  },
  setup() {
    const isCategoryOpenRef = ref(false)
    const setCategoryOpen = (state: any) => isCategoryOpenRef.value = state

    const isBookDetailRef = ref(false)
    const setBookDetailOpen = (state: any) => isBookDetailRef.value = state

    const pageData = reactive <any>({
      availableBooks: 0,
      bookList: [],
      selectedBook: {
        id: null,
        name: null
      },
      footerKey: 0
    })

    const formData = {}

    const bookDetailData = {}

    const getAvailableBooks = async () => {
      const queryAvailableBooks: any = await useFirebaseFirestore()
          .getBookLists()
      // console.log(queryAvailableBooks)
      // console.log(queryAvailableBooks.status)
      // console.log(queryAvailableBooks.message)
      // console.log(queryAvailableBooks.result)
      
      pageData.bookList = []
      pageData.availableBooks = 0
      pageData.selectedBook = null
      // if (Object.keys(queryAvailableBooks).length > 0 && queryAvailableBooks.constructor === Object) {
      if (queryAvailableBooks.status == 1) {
        // alert('here')
        const reGetUserData: any = await firebaseAuth().getUserData()
        // console.log(reGetUserData.settings.defaultBook)
        pageData.bookList = queryAvailableBooks.data
        pageData.availableBooks = Number(queryAvailableBooks.data.length)
        pageData.selectedBook = pageData.bookList.filter( (item: any) => item.id === reGetUserData.settings.defaultBook)[0]
        localStorage.setItem('bookLists', JSON.stringify(queryAvailableBooks.data))
        localStorage.setItem('selectedBook', JSON.stringify(pageData.selectedBook))
        // console.log(pageData.bookList)
        // console.log(pageData.availableBooks)
        // console.log(pageData.selectedBook)
        // console.log(pageData.selectedBook.id)
      }
    }

    const createBookAlert = async () => {
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: 'Create New Book',
          inputs: [
            {
              name: 'newBookName',
              id: 'newBookName-id',
              value: '',
              placeholder: 'Enter New Book Name',
            }
          ],
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                // console.log('Confirm Cancel')
              },
            },
            {
              text: 'Ok',
              handler: async (data) => {
                // console.log(data)
                const createBooks: any = await useFirebaseFirestore()
                  .createBooks(data.newBookName)
                let alertTitle = 'Alert'
                if (createBooks.status == 1) {
                  alertTitle = 'Success'
                  getAvailableBooks()
                  pageData.footerKey++
                }
                await useGlobalHelpers()
                  .showAlerts(alertTitle, '', createBooks.message)
                // console.log('Confirm Ok')
              },
            },
          ],
        });
      return alert.present()
    }

    const makeSelectedBook = async (newDefaultItem: any) => {
      // alert('here')
      // console.log(item)
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: 'Change Selected Book to ' + newDefaultItem.name,
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                // console.log('Confirm Cancel')
              },
            },
            {
              text: 'Ok',
              handler: async () => {
                const updateBookDetail: any = await useFirebaseFirestore()
                    .updateBookDetail('changeDefault', newDefaultItem)
                // console.log(updateBookDetail)
                await firebaseAuth().getUserData()
                // localStorage.setItem('selectedBook', JSON.stringify(newDefaultItem))
                let alertTitle = 'Alert'
                if (updateBookDetail.status == 1) {
                  alertTitle = 'Success'
                  getAvailableBooks()
                }
                await useGlobalHelpers()
                  .showAlerts(alertTitle, '', updateBookDetail.message)
              },
            },
          ],
        });
      return alert.present()
    }

    const renameBook = async (item: any) => {
      // alert('here')
      // console.log(item)
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: 'Rename Book ' + item.name + ' to: ',
          inputs: [
            {
              name: 'renameBook',
              id: 'renameBook-id',
              value: item.name,
              placeholder: 'Enter Book Name',
            }
          ],
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                // console.log('Confirm Cancel')
              },
            },
            {
              text: 'Ok',
              handler: async (data) => {
                // console.log(data)
                item.renameBook = data.renameBook
                const updateBookDetail: any = await useFirebaseFirestore()
                    .updateBookDetail('renameBook', item)
                let alertTitle = 'Alert'
                if (updateBookDetail.status == 1) {
                  alertTitle = 'Success'
                  getAvailableBooks()
                }
                await useGlobalHelpers()
                  .showAlerts(alertTitle, '', updateBookDetail.message)
              },
            },
          ],
        });
      return alert.present()
    }

    const shareBook = async (item: any) => {
      // alert('here')
      // console.log(item)
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: 'Share Book ' + item.name + ' to: ',
          inputs: [
            {
              name: 'shareEmailAddress',
              id: 'shareEmailAddress',
              value: '',
              placeholder: 'Enter Email User',
            }
          ],
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                // console.log('Confirm Cancel')
              },
            },
            {
              text: 'Ok',
              handler: async (data) => {
                // console.log(data)
                const userData: any = await firebaseAuth().userData
                // console.log(userData.value.email)
                if (data.shareEmailAddress != userData.value.email) {
                  item.shareEmailAddress = data.shareEmailAddress
                  const updateBookDetail: any = await useFirebaseFirestore()
                      .updateBookDetail('shareBook', item)
                  let alertTitle = 'Alert'
                  if (updateBookDetail.status == 1) {
                    alertTitle = 'Success'
                    getAvailableBooks()
                  }
                  await useGlobalHelpers()
                    .showAlerts(alertTitle, '', updateBookDetail.message)
                } else {
                  await useGlobalHelpers()
                    .showAlerts('Alert', '', 'Cannot Share To Yourself')
                }
              },
            },
          ],
        });
      return alert.present()
    }

    const stopShare = async (item: any, stopShareData: any) => {
      // console.log(item)
      // console.log(stopShareData)
      let alertText = 'Stop Share ' + item.name + ' with '
      if (stopShareData.name) alertText += stopShareData.name + ' -'
      alertText += ' ' + stopShareData.email
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: alertText,
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                // console.log('Confirm Cancel')
              },
            },
            {
              text: 'Ok',
              handler: async () => {
                const userData: any = await firebaseAuth().userData
                if (stopShareData.uid == userData.value.uid) {
                  await useGlobalHelpers()
                    .showAlerts('Sorry', '', 'You cannot stop share your own book')
                } else if (userData.value.uid != item.createdBy.uid) {
                  await useGlobalHelpers()
                    .showAlerts('Sorry', '', 'Only creator of this book can stop sharing')
                } else {
                  item.stopShareData = stopShareData
                  const updateBookDetail: any = await useFirebaseFirestore()
                      .updateBookDetail('stopShare', item)
                  let alertTitle = 'Alert'
                  if (updateBookDetail.status == 1) {
                    alertTitle = 'Success'
                    getAvailableBooks()
                  }
                  await useGlobalHelpers()
                    .showAlerts(alertTitle, '', updateBookDetail.message)
                }
              },
            },
          ],
        });
      return alert.present()
    }

    const editCategories = async (item: any) => {
      // console.log('editCategories')
      // console.log(item)
      Object.assign(formData, {...item})
      setCategoryOpen(true)
    }

    const submitCategoryForm = async (event: any) => {
      // console.log(event)
      event.action == 'submit' ? setCategoryOpen(false) : null
      await getAvailableBooks()
    }

    const closeCategoryForm = (event: any) => {
      // console.log(event)
      event.action == 'close' ? setCategoryOpen(false) : null
    }

    const showBookDetail = (item: any) => {
      // console.log(item)
      Object.assign(bookDetailData , item)
      setBookDetailOpen(true)
    }

    const submitBookDetail = async (event: any) => {
      // console.log(event)
      setBookDetailOpen(false)
      switch (event.action) {
        case 'renameBook':
          await renameBook(event.bookDetail)
          break
        case 'editCategory':
          await editCategories(event.bookDetail)
          break
        case 'addShare':
          await shareBook(event.bookDetail)
          break
        case 'stopShare':
          await stopShare(event.bookDetail, event.stopShare)
          break
      }
      // await getAvailableBooks()
    }

    const closeBookDetail = (event: any) => {
      // console.log(event)
      event.action == 'close' ? setBookDetailOpen(false) : null
    }

    onMounted ( async () => {
      await getAvailableBooks()
    })

    onBeforeUpdate ( () => {
      setCategoryOpen(false)
      setBookDetailOpen(false)
    })

    onUpdated ( async () => {
      await getAvailableBooks()
    })

    return {
      // functions
      router: useRouter(),
      createBookAlert,
      makeSelectedBook,
      renameBook,
      shareBook,   
      stopShare,   
      isCategoryOpenRef,
      setCategoryOpen,
      isBookDetailRef,
      setBookDetailOpen,
      showBookDetail,
      editCategories,
      closeCategoryForm,
      submitCategoryForm,
      closeBookDetail,
      submitBookDetail,

      // properties
      pageData,
      formData,
      bookDetailData,

      // icons
      add,
      checkmarkCircleOutline,
      informationCircleOutline
    }
  }
})
</script>