
import { 
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonIcon,
  // IonInput,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonReorder, 
  IonReorderGroup,
  IonTitle,
  IonToolbar,
  alertController
} from '@ionic/vue'
import { close, checkmarkOutline, removeOutline, addOutline, reorderFourOutline } from 'ionicons/icons'
import { defineComponent, reactive, computed } from 'vue'
import useGlobalHelpers from "../helpers/global-helpers"
import useFirebaseFirestore from "../hooks/firebase-firestore"

export default defineComponent({
  name: 'IncomeForm',
  components: { 
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonContent,
    IonIcon,
    // IonInput,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonReorder, 
    IonReorderGroup,
    IonTitle,
    IonToolbar
  },
  props: { 
    formData: {
      type: Object,
      default: () => ({
        id: null,
        name: null,
        settings: {
          categories: {
            income: [],
            expense: []
          }
        }
      })
    }
  },
  setup (props: any, ctx) {
    const formItem = reactive<any>({
     id: null,
      name: null,
      settings: {
        categories: {
          income: [],
          expense: []
        }
      }
    })

    // console.log(props.formData)
    const putTitle = computed(() => {
      const result = 'Update Category'
      Object.assign (formItem, {
        id: props.formData.id,
        name: props.formData.name,
        settings : {
          categories: {
            income: props.formData.settings.categories.income,
            expense: props.formData.settings.categories.expense
          }
        }
      })
      // console.log(formItem)
      return result
    })

    const addItem = async (type: string) => {
      // console.log(type)
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: 'Create New Category',
          inputs: [
            {
              name: 'newCategoryName',
              id: 'newCategoryName-id',
              value: '',
              placeholder: 'Enter New Category Name',
            }
          ],
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                // console.log('Confirm Cancel')
              },
            },
            {
              text: 'Ok',
              handler: async (data) => {
                // console.log(data)
                const newCategoryName = data.newCategoryName.split(/ /g).map((val: any) => val[0].toUpperCase() + val.slice(1)).join(' ')
                if (type == 'income') formItem.settings.categories.income.push(newCategoryName)
                if (type == 'expense') formItem.settings.categories.expense.push(newCategoryName)
                // console.log('Confirm Ok')
                console.log(formItem)
                await useGlobalHelpers()
                  .showAlerts('Category Added', '', 'Category Added, Remember To Click Save to save changes')
              },
            },
          ],
        });
      return alert.present()
    }

    const removeItem = async (type: string, removeItem: any) => {
      // console.log(type)
      // console.log(removeItem)
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: 'Delete This Category',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                // console.log('Confirm Cancel')
              },
            },
            {
              text: 'Ok',
              handler: async () => {
                // console.log(data)
                if (type == 'income')  formItem.settings.categories.income = formItem.settings.categories.income.filter((item: string) => item != removeItem)
                if (type == 'expense') formItem.settings.categories.expense = formItem.settings.categories.expense.filter((item: string) => item != removeItem)
                // console.log('Confirm Ok')
                // console.log(formItem)
                await useGlobalHelpers()
                  .showAlerts('Category Removed', '', 'Category Removed, Remember To Click Save to save changes')
              },
            },
          ],
        });
      return alert.present()
    }

    const submitForm = async () => {
      // console.log('submit Income Form')
      // console.log(formItem)

      await useFirebaseFirestore()
        .updateCategoryList(formItem)
      // console.log(response)
      ctx.emit('submit', 'done')
    }
    
    const closeForm = () => {
      // console.log('close Income Form')
      const response = {
        'action': 'close',
        'formData': null
      }
      // console.log(response)
      ctx.emit('close', response)
    }

    return {
      //method
      submitForm,
      closeForm,
      removeItem,
      addItem,

      //data
      formItem,

      //computed
      putTitle,

      //icon
      close,
      checkmarkOutline,
      removeOutline,
      addOutline,
      reorderFourOutline
    }
  }
})
