
import { 
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonIcon,
  IonHeader,
  IonItem,
  IonList,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/vue'
import { close, createOutline, closeOutline, shareSocialOutline,albumsOutline, trashOutline } from 'ionicons/icons'
import { defineComponent, reactive, computed } from 'vue'

export default defineComponent({
  name: 'IncomeForm',
  components: { 
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonContent,
    IonIcon,
    IonHeader,
    IonItem,
    IonList,
    IonLabel,
    IonPage,
    IonTitle,
    IonToolbar
  },
  props: { 
    bookProp: {
      type: Object,
      default: () => ({
        id: null,
        createdBy: {
          email: null,
          name: null,
          uid: null,
        },
        hasAccess: null,
        hasAccessData: [],
        name: null,
        settings: {
          categories: {
            income: [],
            expense: []
          }
        },
        updatedAt: null,
        createdAt: null
      })
    }
  },
  setup (props: any, ctx) {
    const bookDetail = reactive<any>({
      id: null,
      createdBy: {
        email: null,
        name: null,
        uid: null,
      },
      hasAccess: null,
      hasAccessData: [],
      name: null,
      settings: {
        categories: {
          income: [],
          expense: []
        }
      },
      updatedAt: null,
      createdAt: null
    })

    const putTitle = computed(() => {
      const result = 'Book Detail'
      Object.assign (bookDetail, props.bookProp)
      // console.log(bookDetail)
      return result
    })

    const submitForm = async (action: string, stopShare?: any) => {
      // console.log('submit Income Form')
      // console.log(formItem)

      const response = {
        'action': action,
        'bookDetail': bookDetail,
        'stopShare': stopShare
      }
      // console.log(response)
      ctx.emit('submit', response)
    }
    
    const closeForm = () => {
      // console.log('close Income Form')
      const response = {
        'action': 'close',
        'bookDetail': null
      }
      // console.log(response)
      ctx.emit('close', response)
    }

    return {
      //method
      submitForm,
      closeForm,
    
      //data
      bookDetail,

      //computed
      putTitle,

      //icon
      close,
      createOutline,
      closeOutline,
      shareSocialOutline,
      albumsOutline,
      trashOutline
    }
  }
})
